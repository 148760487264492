import React, { useEffect } from 'react'
import Editorial from '../components/Editorial'
import EditorialContent from '../components/EditorialContent'
import JournalName from '../components/JournalName'
import Menu from '../components/Menu'
import './styles.css'

const EditorialPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="home-section-one">
        <JournalName name="International Journal of Contemporary Accounting Issues (IJCAI)" />
      </div>
      <div className="home-section-two">
        <Menu />
      </div>
      <div className="home-section-three">
        <Editorial />
        <EditorialContent />
      </div>
    </div>
  )
}

export default EditorialPage