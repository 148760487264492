import React from 'react'

const JournalName = ({ name }) => {
  return (
   <div className="journal-name__wrapper container">
       <div className="journal__container">
          <h4>{name}</h4>
       </div>
   </div>
  )
}

export default JournalName