import React from 'react'
import SideMenu from './SideMenu'
import './styles.css'

const Instructions = () => {
    const scroll = (value) => {
        const section = document.querySelector( value );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      };
  return (
    <div className="instructions__wrapper container">
      <h1>Instruction for Authors</h1>
      <div className="instruction-content__container">
          <div className="instruction-sections">
            <ul>
                <li><span onClick={() => scroll("#editor")}>Editorial Procedure</span></li>
                <li><span onClick={() => scroll("#peer-preview")}>The IJCAI's Peer-Review Procedure</span></li>
                <li><span onClick={() => scroll("#quality")}>Quality Criteria Evaluation</span></li>
                <li><span onClick={() => scroll("#ethics")}>Code of Ethics</span></li>
                <li><span onClick={() => scroll("#original")}>Originality</span></li>
                <li><span onClick={() => scroll("#guide")}>Author Guidelines</span></li> 
                <li><span onClick={() => scroll("#manuscript")}>Manuscript Submissions</span></li> 
                <li><span onClick={() => scroll("#format")}>Format Guide</span></li>
                <li><span onClick={() => scroll("#books")}>Books</span></li>
                <li><span onClick={() => scroll("#period")}>Periodicals</span></li>
                <li><span onClick={() => scroll("#internet")}>Internet Sources</span></li>
                <li><span onClick={() => scroll("#info")}>Information Sources</span></li>
                <li><span onClick={() => scroll("#translate")}>Non- English reference book, title translated in English</span></li>
                <li><span onClick={() => scroll("#disclosure")}>Disclosure</span></li> 
                <li><span onClick={() => scroll("#publish")}>Papers Accepted for Publication</span></li>
            </ul>
          </div>
          <SideMenu />
      </div>
    </div>
  )
}

export default Instructions