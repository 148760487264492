import axios from 'axios';
import React, { useState } from 'react'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Subscribe');

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { email };
    axios
        .post('https://sheet.best/api/sheets/a845db89-903d-42ae-9367-82f75d54f41d', data)
        .then((response) => {
          if (response.status === 200) {
            setEmail('');
            setButtonText('Successful');
            setTimeout(() => {
              setButtonText('Subscribe');
            }, 500);
          }
        })
  }
  return (
    <div id="footer" className="footer__wrapper">
        <div className="footer-content__container">
            <h5>Subscribe to get our Newsletter</h5>
            <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder='Your email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">{buttonText}</button>
            </form>
            <div className="footer-menu">
                <div className="menu-item">
                    <a href="/">Journal</a>
                </div>
                <div className="menu-item">
                    <a href="/policy">Privacy Policy</a>
                </div>
                <div className="menu-item">
                    <a href="/terms">Terms & Conditions</a>
                </div>
            </div>
            <div className="copyright-section">
                <p>&copy; {currentYear} ICAN</p>
            </div>
        </div>
    </div>
  )
}

export default Footer