import React from 'react'
import { Link } from 'react-router-dom'

const Menu = () => {
  const scroll = (value) => {
      const section = document.querySelector( value );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
  return (
    <div className="menu__wrapper">
        <div className="menu__container container">
          <div className="menu__links">
            <Link className="menu-item" to="/">
              <span>Home</span>
              <i className="fa fa-chevron-down" aria-hidden="true" />
            </Link>
            <Link className="menu-item" to="/editorial-team">
              <span>About</span>
              <i className="fa fa-chevron-down" aria-hidden="true" />
            </Link>
            <Link className="menu-item" to="/instructions">
              <span>Contribute</span>
              <i className="fa fa-chevron-down" aria-hidden="true" />
            </Link>
            <div className="menu-item" onClick={() => scroll('#header')}>
              <span>Browse</span>
              <i className="fa fa-chevron-down" aria-hidden="true" />
            </div>
          </div>
        </div>
    </div>
  )
}

export default Menu