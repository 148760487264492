import './App.css';
import Footer from './components/PageFooter';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import EditorialPage from './pages/EditorialPage';
import Aims from './pages/Aims';
import JournalPage from './pages/JournalPage';
import Articles from './pages/Articles';
import ConferenceProceedings from './pages/ConferenceProceedings';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
          <Routes>
            <Route path="/instructions" element={<Home />} />
            <Route path="/editorial-team" element={<EditorialPage />} />
            <Route path="/aims" element={<Aims />} />
            <Route path="/" element={<JournalPage />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/conferenceproceedings" element={<ConferenceProceedings />} />
          </Routes>
        <Footer />
      </div>
     </Router>
  );
}

export default App;
