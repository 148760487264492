import React from 'react'
import SideMenu from './SideMenu'
import './styles.css'

const Editorial = () => {
  return (
    <div className='instructions__wrapper container'>
        <h1>Editorial team</h1>
        <div className="editorial-content__container">
            <div className="editorial-sections">
                <div className="editorial-item">
                    <h5>Editor-in-Chief</h5>
                    <p>Professor Kabiru Isa Dandago</p>
                </div>
                <div className="editorial-item">
                    <h5>Consulting-Editors-in Chiefs</h5>
                    <p> Professor Felix Amenkhienano</p>
                    <p>Professor Teerooven Soobaroyen</p>
                    <p>Professor Emmanuel Adegbite</p>
                </div>
            </div>
            <SideMenu />
        </div>
    </div>
  )
}

export default Editorial