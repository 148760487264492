import React from 'react'

const PageContent = () => {
  return (
    <div className="page-content__wrapper container">
        <div className="page-content__container">
            <div id="editor" className="content-item">
                <h5>Editorial Procedure</h5>
                <div className="item-data">
                    <p>All authors will receive notification of acknowledgment comprising a Manuscript ID upon receipt of the submission, and the Editorial decision concerning publication.</p>
                    <br />
                    <p>IJCAI maintains a double-blind peer-review process. As a result, material that might identify authorship of submissions should not be contained in the manuscript. Authors are required to use the Structured Title Page the submission&apos;s title, abstract, keywords, authors, acknowledgment, funding, and paper classification. </p>
                    <br />
                    <p>Notification of the editorial decision is usually provided within twelve weeks from the submission of the manuscript. </p>
                    <br />
                    
                    <p>For full information, authors are directed to read Authors Guidelines of the IJCAI.</p>
                </div>
            </div>
            <div id="peer-preview" className="content-item">
                <h5>The IJCAI's Peer-Review Procedure</h5>
                <div className="item-data">
                    <p>In the peer-review procedure, the IJCAI ensure two referees independently assess the scientific quality of the submitted manuscripts. </p>
                    <br />
                    <p>Peer reviewers are sometimes called “experts” chosen by journal editors to provide written assessment of the written research.  The peer review process serves the purpose of improving the reporting of research as well as identifying the most appropriate and highest quality material for the review.</p>
                </div>
            </div>
            <div id="quality" className="content-item">
                <h5>Quality Criteria Evaluation</h5>
                <div className="item-data">
                    <p>In the peer-review procedure, the IJCAI ensures at least two referees independently assess the scientific quality of the submitted manuscripts. </p>
                    <br />
                    <p>IJCAI submission review process adopts a seven stage quality criteria evaluation of manuscripts submitted. These are:</p>
                    <br />
                    <p>1.   One of the the three editors in Chief examine the paper for its quality and appropriateness for review. If the criteria set are not met, the manuscript is rejected, and the author duly informed. </p>
                    <br />
                    <p>2.	One of the the three editors in Chief sends the anonymous  article, to a handling editor who will choose and send to two reviewers, who are expected to complete the review in eight weeks. IJCAI is developmental in orientation and reviewers are encouraged to offer suggestions on how potential identified in manuscripts could be realised. </p>
                    <br />
                    <p>Comments to authors are on the appropriateness and adequacy of the theoretical or conceptual framework, literature review, method, results and discussion, policy/implications to society, practice, theory and method, and conclusions. Reviewers often include suggestions for the improving of the quality of the manuscript. Comments to the editor are in the nature of the significance of the work and its potential contribution to the literature. </p>
                    <br />
                    <p>3.	The handling editor examines the review and recommends to the concerned editor in chief, a decision to reject, reject and resubmit, revise and resubmit or accept Editorial decisions are ultimately made by the concerned editor in Chief. </p>
                    <br />
                    <p>4.	The authors have to make a decision as to the best approach to address the reviewers' comments as well as the handling editor's where applicable. A revised manuscript alongside a revision template in a tabular form is required. </p>
                    <br />
                    <p>5.	The handling Editor sends the revised manuscript out for a second review. Usually, all of the original reviewers will be requested to re-examine the article.</p>
                    <br />
                    <p>6.	When the reviewers have completed their work, the handling Editor reviews their comments and recommends an editorial decision to the concerned editor in chief. There could be up to four rounds of review.</p>
                    <br />
                    <p>7.	Finally, if a manuscript it accepted, an acceptance letter is sent to all the author(s), the paper is referred to the Press.  </p>
                    <br />
                    <p>The Secretariat ensures that the paper observes the correct style (in-text citations, the reference list, and tables are typical areas of concern, clarity, and grammar). The authors are asked to respond to any minor queries by the Secretariat. Following these corrections, page proofs are emailed to the corresponding authors for their final approval. At this point, only essential changes are accepted. Finally, the article appears in the Journal and is posted on-line.</p>
                </div>
            </div>
            <div id="ethics" className="content-item">
                <h5>Code of Ethics</h5>
                <div className="item-data">
                    <p>The IJCAI Editorial Board expects the highest ethical considerations in submitted manuscripts. We expect all our stakeholders, namely: authors, editors, reviewers, and editorial board members, to abide by the Journal&apos;s Code of Ethics.</p>
                    <br />
                    <p>For full details, please visit the Journal website at: </p>
                    <p>http://www.icanacademicjournal.org</p>
                </div>
            </div>
            <div id="original" className="content-item">
                <h5>Originality</h5>
                <div className="item-data">
                    <p>The author(s) must ensure that manuscript submitted to IJCAI review reflects an original work. The author is expected to check  manuscripts for any possible similarity or plagiarism, prior to submission. </p>
                    <br />
                    <p>As part of the review process, thehandling Editor is expected to present full reports of the originality check for each manuscript to the concerned Editor-in-Chief.</p>
                </div>
            </div>
           
           
            <div id="guide" className="content-item">
                <h5>Author Guidelines</h5>
                <div className="item-data">
                    <p>IJCAI receives articles written in English for publication in the review by adopting the APA Style Guide.  In addition, manuscripts submitted for the IJCAI must be in English.</p> 
                    <br />
                    <span id="manuscript">Manuscript Submissions</span>
                    <p>1.   Only papers comprising original, unpublished work, which is not being considered for publication elsewhere, will be received.</p> 
                    <p>2. 	Manuscripts must be submitted electronically using IJCAIs online submission and peer review system, Editorial Management (EM), at https://www.icanacademicjournal.org/.</p>
                    <br />
                    <p>3. 	Papers considered for the section or classification labelled “Experimental” or “Case/ Field study/ other” or “Other” must be accompanied by copies of the full research instrument employed in collecting the data.</p> 
                    
                    <p>4. 	Manuscripts should be directed to </p>
                    <p>Editor-in-Chief</p>
                    <p>International Journal of Contemporary Accounting Issues</p>
                    <p>The Institute of Chartered Accountants of Nigeria, ICAN</p>
                    <p>16, Idowu Taylor Street</p>
                    <p>Victoria Island</p>
                    <p>Nigeria</p>
                    <p>For assistance, contact: ioanaso@ican.org.ng</p>
                </div>
            </div>
            <div id="format" className="content-item">
                <h5>Format Guide</h5>
                <div className="item-data">
                    <p>1.	Manuscripts must be prepared in accordance with the APA Style Guide (Sixth Edition). In addition, all pages must be typed in 12-point Times New Roman, double-spaced (including references and footnotes) on one side of standard A4 size (8.27’ x 11.69’) paper with  margins of 1” on all the four sides and pages serially numbered.</p>
                    <p>2.	All manuscripts must be submitted to the IJCAI Editorial Management System (EMS) in Microsoft Word 2003, 2007, or 2010 (.doc or .docx). </p>
                    <p>3.	All pages, including, tables, figures, illustrations, appendices, and references should be numbered serially.</p> 
                    <p>4.	The cover page must only consists of the title, author&apos;s name and affiliation, address, phone, fax, and email numbers of the corresponding author, acknowledgments and information on grants received if any.</p>
                    <br />       
                    <p>5.	In respect of online submission, the cover page should be saved as a separate file and must not be part of the manuscript during online upload to ensure blind review.</p>
                    <br />               
                    <p>6.	An abstract of not more than 250 words must be presented on the second page closely next to the cover page. The abstract should include the research objective, method of examination, and principal findings, and must be followed by five keywords and up to eight JEL classifications especially for indexing purposes.</p>
                    <br />
                    <p>7.	Use the automatic footnote function only.</p>
                    <br />
                    <p>8.	Tables, figures or illustration must be presented within the manuscript text as follows:</p>
                    <br />
                    <p>a)	Tables are labelled according to the section in which they appear. For example, tables in Section 2 are numbered sequentially: Table 2.1, Table 2.2, etc. The table title is placed on top of the table, left justified and in the following format:</p>
                    <p>Table 2.1: Short Title</p>
                    <br />
                    <p>If the table is more than one page, the continued table on the subsequent page should show that it is a continuation. If the table consists of a citation, the source of the reference should be placed below the table.</p>
                    <br />
                    <p>b)	Figures such as map, charts, graphs, diagrams, photographs should be labelled according to the section in which they appear. For example, figures in Section 3 are numbered sequentially: Figure 3.1, Figures 3.2, etc. The title is placed below the figure, left justified and in the following format:</p>
                    <p>Figure 3.1: Short Title </p>
                    <br />
                    <p>9.	Any author that uses Microsoft Word&apos;s Track Changes function must ensure to click on “accept all changes” before submitting the electronic files.</p> 
                    <br />
                    <p>10.	Referencing Style (APA 6th Edition)</p>
                    <br />
                    <p>a)	The references list must be arranged in alphabetical order according to the authors&apos; last names.</p> 
                    <p>b)	If there is more than one work by the same author, order them according to their publication date  - newest to oldest (i.e., a 2009 publication comes before a 2008 publication). </p>
                    <p>c)	For multiple authors of a source, use “&amp;” instead of “and”. </p>
                    <p>d)	Capitalize only the first word of the heading and the subheading if there are one, and any proper names. For example, only those words that are usually capitalized.  </p>
                    <p>e)	Italicize the title of the book, the title of the journal/serial and the title of the web document. </p>
                    <p>f)	Manuscripts submitted to ABC should strictly follow the ABC guide (Xth edition). </p>
                    <p>g)	Every citation in the text must have the detailed reference in the References section.</p>
                    <p>h)	Every reference listed in the References section must be cited in the text.</p>
                    <p>i)	Avoid the use of “et al.” at the end of the authors&apos; names in the References section.</p>
                </div>
            </div>
            <div id="books" className="content-item">
                <h5>Books</h5>
                <div className="item-data">
                    <p>Book with the place of publication - Airey, D. (2014). Logo design love: A guide to creating iconic brand identities. Berkeley, CA: New Riders. </p>
                    <p>Book with editors &amp; edition - Collins, C., &amp; Jackson, S. (Eds.). (2010). Sport in Aotearoa/New Zealand society. South Melbourne, Australia: Thomson. </p>
                    <p>Book with same author &amp; publisher - MidCentral District Health Board. (2009). District annual plan 2008/09. Palmerston North, New Zealand: Author. </p>
                    <p>Chapter in an edited book - Dear, J., &amp; Underwood, M. (2006). What is the role of exercise in the prevention of back pain? In D. MacAuley&amp; T. Best (Eds.), Evidence-based sports medicine (2nd ed., pp. 257-280). Malden, MA: Blackwell.</p> 
                </div>
            </div>
            <div id="period" className="content-item">
                <h5> Periodicals</h5>
                <div className="item-data">           
                    <p>Journal article with more than one author (print) - Gabbett, T., Jenkins, D., &amp; Abernethy, B. (2010). Physical collisions and injury during professional rugby league skills training. Journal of Science and Medicine in Sport, 13(6), 578-583. </p>
                    <p>Journal article - 8 or more authors - Crooks, C., Ameratunga, R., Brewerton, M., Torok, M., Buetow, S., Brothers, S., … Jorgensen, P. (2010). Adverse reactions to food in New Zealand children aged 0-5 years. New Zealand Medical Journal, 123(1327). Retrieved from http://www.nzma.org.nz/journal/123-1327/4469/ </p>
                </div>
            </div>
            <div id="internet" className="content-item">
                <h5>Internet Sources</h5>
                <div className="item-data">
                    <p>Internet - no author, no date--Pet therapy. (n.d.). Retrieved from htttp://www.holisticonline.com/stress/stress_pet-therapy.htm</p> 
                    <p>Internet -  Organisation / Corporate author-- SPCA New Zealand. (2012). Your dog may be dying from the heat [Press release]. Retrieved from http://www.rnzspca.org.nz/news/press-releases/360-your-dog-may-be-dying-from-the-heat </p>
                </div>
            </div>
            <div id="info" className="content-item">
                <h5>Information Sources</h5>
                <div className="item-data">
                    <p>Act (statute / legislation) -- Copyright Act 1994. (2011, October 7). Retrieved from http://www.legislation.govt.my</p>
                    <p>Blog post -- Liz and Ellory. (2011, January 19). The day of dread(s) [Web log post]. Retrieved from http://www.travelblog.org/Oceania/Australia/Victoria/Melbourne/St-Kilda/blog-669396.html</p>
                    <p>Brochure / Pamphlet (no author) - Ageing well: How to be the best you can be [Brochure]. (2009). Wellington, New Zealand: Ministry of Health.</p>
                    <p>Conference Paper - Williams, J., &amp;Seary, K. (2010). Bridging the divide: Scaffolding the learning experiences of the mature age student. In J. Terrell (Ed.), Making the Links: Learning, teaching and high-quality student outcomes. Proceedings of the 9th Conference of the New Zealand Association of Bridging Educators (p. 104-116). Wellington, New Zealand.</p> 
                    <p>DVD / Video / Motion Picture (including Clickview&amp;Youtube)--Gardiner, A., Curtis, C., &amp; Michael, E. (Producers), &amp; Waititi, T. (Director). (2010). Boy, Welcome to my interesting world [DVD]. New Zealand: Transmission.</p> 
                    <p>Magazine - Ng, A. (2011, October-December). Brush with history. Habitus, 13, 83-87.</p>
                    <p>Newspaper article (no author)--Little blue penguins homeward bound. (2011, November 23). Manawatu Standard, p. 5</p> 
                    <p>Podcast (audio or video) -- Rozaieski, B. (2011). Logan cabinet shoppe: Episode 37: Entertainment center molding [Video podcast]. Retrieved from http://blip.tv/xxx</p>
                    <p>Software (including apps - UBM Medica.(2010). iMIMS (Version1.2.0) [Mobile application software].Retrieved from http://itunes.apple.com</p>
                    <p>Television programme - Flanagan, A., &amp;Philipson, A. (Series producers &amp; directors).(2011). 24 hours in A &amp; E [Television series]. Belfast, Ireland: Channel 4.</p> 
                    <p>Thesis (print) - Smith, T. L. (2008). Change, choice and difference: The case of RN to BN degree programmes for registered nurses (Master&apos;s thesis). The Victoria University of Wellington, Wellington, New Zealand.</p>
                    <p>Thesis (online) - Mann, D. L. (2010). Vision and expertise for interceptive actions in sport (Doctoral dissertation, The University of New South Wales, Sydney, Australia). Retrieved from http://handle.unsw.edu.au/1959.4/44704. </p>
                </div>
            </div>
            <div id="translate" className="content-item">
                <h5>Non- English reference book, title translated in English </h5>
                <div className="item-data">       
                    <p>Act (statute / legislation) -- Copyright Act 1994. (2011, October 7). Retrieved from http://www.legislation.govt.my</p>
                    <p>Blog post -- Liz and Ellory. (2011, January 19). The day of dread(s) [Web log post]. Retrieved from http://www.travelblog.org/Oceania/Australia/Victoria/Melbourne/St-Kilda/blog-669396.html</p>
                    <p>Brochure / Pamphlet (no author) - Ageing well: How to be the best you can be [Brochure]. (2009). Wellington, New Zealand: Ministry of Health.</p>
                    <p>Conference Paper - Williams, J., &amp;Seary, K. (2010). Bridging the divide: Scaffolding the learning experiences of the mature age student. In J. Terrell (Ed.), Making the Links: Learning, teaching and high-quality student outcomes. Proceedings of the 9th Conference of the New Zealand Association of Bridging Educators (p. 104-116). Wellington, New Zealand. </p>
                    <p>DVD / Video / Motion Picture (including Clickview&amp;Youtube)--Gardiner, A., Curtis, C., &amp; Michael, E. (Producers), &amp; Waititi, T. (Director). (2010). Boy, Welcome to my interesting world [DVD]. New Zealand: Transmission.</p> 
                    <p>Magazine - Ng, A. (2011, October-December). Brush with history. Habitus, 13, 83-87.</p>
                    <p>Newspaper article (no author)--Little blue penguins homeward bound. (2011, November 23). Manawatu Standard, p. 5</p> 
                    <p>Podcast (audio or video) -- Rozaieski, B. (2011). Logan cabinet shoppe: Episode 37: Entertainment center molding [Video podcast]. Retrieved from http://blip.tv/xxx</p>
                    <p>Software (including apps - UBM Medica.(2010). iMIMS (Version1.2.0) [Mobile application software].Retrieved from http://itunes.apple.com</p>
                    <p>Television programme - Flanagan, A., &amp;Philipson, A. (Series producers &amp; directors).(2011). 24 hours in A &amp; E [Television series]. Belfast, Ireland: Channel 4. </p>
                    <p>Thesis (print) - Smith, T. L. (2008). Change, choice and difference: The case of RN to BN degree programmes for registered nurses (Master&apos;s thesis). The Victoria University of Wellington, Wellington, New Zealand.</p>
                    <p>Thesis (online) - Mann, D. L. (2010). Vision and expertise for interceptive actions in sport (Doctoral dissertation, The University of New South Wales, Sydney, Australia). Retrieved from http://handle.unsw.edu.au/1959.4/44704.</p>
                    <br />
                    <p>Real Academia Espanola. (2001). Diccionario de la lingua Espanola [Dictionary of the Spanish Language] (22nd ed.). Madrid, Spain: Author.</p>
                    <p>Authors must save copies of the manuscript's reviews and decision letters outside of IJCAI's electronic Editorial Management System (EMS). Records will remain available on the EMS only for 12 months after the final decision. All files older than 12 months will be archived in the EMS. EMS's archived files are inaccessible for IJCAI authors and the IJCAI office.</p>
                </div>
                <h5 id="disclosure">Disclosure</h5>
                <div className="item-data">
                    <p>Together with the paper being submitted, authors are requested to enclose a copy of any other paper, whether or not published elsewhere, which shares data or modelling analysis with the proposed manuscript. The implications of the submitted manuscript must be clearly distinguishable from other such papers. If the proposed paper is founded on an experiment, survey, or other data manipulations, participants/respondents, or variables involved in that collection are reflected in the paper, authors are required to include a document defining and clarifying the circumstances and estimating any impact on the results. The instrument may be sent to reviewers. All reviews are blind. </p>
                    <br />
                    <span>Revised Submissions</span>
                    <p>Please note that IJCAI 's policy entails revisions to be submitted within four weeks of the date of the review letter. Otherwise, the manuscript will automatically be withdrawn from IJCAI&apos;s lively files.</p>
                </div>
                <h5 id="publish">Papers Accepted for Publication </h5>
                <div className="item-data">               
                    <p>Authors whose papers have been accepted for publication must ensure its conformity to IJCAI&apos;s requirements as detailed in the International Journal of Accounting Issues (IJCAI) Author Guidelines.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PageContent