import React from 'react'
import JournalName from './JournalName'
import ArticleImage from '../assets/images/article.jpg'
import './styles.css'

const JournalVolume = () => {
  return (
    <div className='journal-volume__wrapper'>
        <div className="journal-volume__container container">
            <div className="volume-section__one">
                <JournalName name="International Journal of Contemporary Accounting Issues (IJCAI)" />
                <p>The International Journal of Contemporary Accounting Issues (IJCAI) is established for the purpose of publishing and maintaining high-quality research in accountancy and finance.</p>
            </div>
            <div className="volume-section__two">
                <div className="volume-card__section">
                    <div className="volume-card">
                        {/* <div className="card-top">
                        <h5>International Journal of Contemporary Accounting Issues (IJCAI)</h5>
                        <p>Editor-in-chief: Prof. Abhulimen R.</p>
                        <p>Anao Benson ldahosa University,</p>
                        <p>Benin City</p>
                        </div>
                        <div className="card-bottom">
                            <h5>PDF</h5>
                        </div> */}
                        <img src={ArticleImage} alt="" />
                    </div>
                    <h2>Volume 12, Issue 1 <br />2023</h2>
                </div>
            </div>
        </div>
    </div>
  )
}

export default JournalVolume