import React, { useEffect } from 'react'
import ConferenceDisplay from '../components/ConferenceDisplay'
import ArticleVolume from '../components/ArticleVolume'
import Menu from '../components/Menu'
import './styles.css'

const ConferenceProceedings = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <div>
      <div className="home-section-one">
        <ArticleVolume  />
      </div>
      <div className="home-section-two">
        <Menu />
      </div>
      <div className="home-section-three">
        <ConferenceDisplay />
      </div>
    </div>
  )
}

export default ConferenceProceedings