import React from 'react'
import ArticlesContainer from './ArticlesContainer'
import SideMenu from './SideMenu'
import './styles.css'
import allArticles from '../utils/articles.json'

const JournalOverview = () => {
  return (
    <div className="instructions__wrapper container">
        <h1>Journal Overview</h1>
        <div className="instruction-content__container journal">
            <div className="instruction-sections journal">
                <p>
                International Journal of Contemporary Accounting Issues (IJCAI) Journal is peer-reviewed and published quarterly. The journal welcomes submissions focussing all areas of accounting research, but welcomes in particular, emerging and contemporary issues across the globe.
                </p>
            </div>
            <SideMenu />
        </div>
        <div className="journal-content">
            <p>Upcoming Special Issue: Research on Application and Impact of IFRS 9 Financial Instruments</p>
            <p>The International Journal of contemporary Accounting Issues Review is pleased to announce a call for papers for a Special Issue of the Journal that will publish academic research related to the application and impact of IFRS 9 Financial Instruments. Research on all areas of IFRS 9, using a range of research methods, is welcome. Read more here.</p>
        </div>
        <ArticlesContainer articles={allArticles.slice(0, 5)} />
    </div>
  )
}

export default JournalOverview