import React from 'react'
import './styles.css'
import Arrow from '../assets/icons/arrow.svg'
import { Link } from 'react-router-dom'

const ArticlesContainer = ({ articles }) => {
  return (
    <div id="articles" className="articles__wrapper">
        <div className="articles__container">
            <div className="articles__header">
                <div className="header-item">
                    <p>Articles</p>
                </div>
            </div>
            <div className="articles__content">
                {articles.map((item, index) => (
                    <div className="article-item" key={index}>
                        <p className="item-title">
                            {item.title}
                        </p>
                        <p className="item-data">
                            {item.authors}
                        </p>
                        <Link to={item.link} className="download" target="_blank" download>Download PDF</Link>
                    </div>
                ))}
            </div>
        </div>
        <div className="view-more">
            View more <img src={Arrow} alt="" />
        </div>
    </div>
  )
}

export default ArticlesContainer