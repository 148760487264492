import React from 'react'
import { NavLink } from 'react-router-dom'
import Submit from '../assets/icons/submit.svg'
import Instruction from '../assets/icons/instruction.svg'
import Policy from '../assets/icons/policy.svg'
import Subscribe from '../assets/icons/subscribe.svg'

const SideMenu = () => {
    const scroll = (value) => {
        const section = document.querySelector( value );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      };
  return (
    <div className='side-menu__wrapper'>
        <div className="side-item">
            <img src={Submit} alt="" />
            <NavLink to = "/articles">
                Articles
            </NavLink>
        </div>
        <div className="side-item">
            <img src={Submit} alt="" />
            <NavLink to = "/conferenceproceedings">
                Conference Proceedings
            </NavLink>
        </div>
        <div className="side-item">
            <img src={Instruction} alt="" />
            <NavLink to = "/instructions">
                Instruction For Authors
            </NavLink>
        </div>
        <div className="side-item">
            <img src={Policy} alt="" />
            <NavLink to = "/editorial-team">
                Editorial Policies
            </NavLink>
        </div>
        <div className="side-item">
            <img src={Subscribe} alt="" />
            <div onClick={() => scroll("#footer")}>
                Subscribe to this journal
            </div>
        </div>
    </div>
  )
}

export default SideMenu