import React, { useState } from 'react'
import ICANLogo from '../assets/images/ican-logo.png'
import JournalLogo from '../assets/icons/ijcai-logo.svg'
import { useNavigate } from 'react-router';

const Header = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const handleSearchValueChange = (e) => {
    const val = e.target.value;
    setSearchValue(val);
  }
  
  const onSearchChange = () => {
    let search = searchValue;
    if (search.includes(' ')) {
      search.replace(' ', '_');
    }
    navigate(`/articles?search=${search}`);
  };

  return (
    <div className="header__wrapper" id='header'>
      <div className="header-content__container container">
        <a className="app-logo" href='/'>
          <img src={ICANLogo} alt="ICAN logo" />
        </a>
        <div className="search-section">
          <input type="search" name="" id="" placeholder='Search' value={searchValue} onChange={handleSearchValueChange} onKeyUp={onSearchChange} />
        </div>
        <div className="journal-logo__section">
          <a className="journal-logo" href='/'>
            <img src={JournalLogo} alt="" />
            <h2>IJCAI</h2>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header