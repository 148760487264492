import React, { useEffect } from 'react'
import Instructions from '../components/Instructions'
import JournalName from '../components/JournalName'
import Menu from '../components/Menu'
import PageContent from '../components/PageContent'
import ScrollToTop from '../components/ScrollToTop'
import './styles.css'

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <div>
      <div className="home-section-one">
        <JournalName name="International Journal of Accounting Issues (IJCAI)" />
      </div>
      <div className="home-section-two">
        <Menu />
      </div>
      <div className="home-section-three">
        <Instructions />
        <PageContent />
      </div>
      <ScrollToTop />
    </div>
  )
}

export default Home