import React from 'react'
import AimsAndScopes from '../components/AimsAndScopes'
import JournalVolume from '../components/JournalVolume'
import Menu from '../components/Menu'
import './styles.css'

const Aims = () => {
  return (
    <div>
      <div className="home-section-one">
        <JournalVolume  />
      </div>
      <div className="home-section-two">
        <Menu />
      </div>
      <div className="home-section-three">
        <AimsAndScopes />
      </div>
    </div>
  )
}

export default Aims