import React from 'react'

const EditorialContent = () => {
  return (
    <div className='editorial-content__wrapper container'>
        <div className="editorial__container">
            <div className="editorial-item">
                <h5>Editors</h5>
                <p>Professor Taiwo Asaolu</p>
                <p>Professor Chinwuba Okafor</p>
                <p>Professor Rufus Salawu</p>
                <p>Professor Ishola Akintoye</p>
                <p>Professor Akintola Owolabi</p>
                <p>Professor Sina Yekini</p>
                <p>Professor Francis Iyoha</p>
                <p>Professor Kemi Yekini</p>
                <p>Assistant Professor Johnson Muse Popoola</p>
                <p>Professor Chuke Nwude</p>
                <p>Dr. Yinka Moses</p>
            </div>
            <div className="editorial-item">
                <h5>Managing Editor</h5>
                <p>Dr. Ijeoma Anaso</p>
            </div>
            <div className="editorial-item">
                <h5>Desk Editor</h5>
                <p>Dr. Yemisi Adedokun</p>
            </div>
            <div className="editorial-item">
                <h5>Editorial Assistants</h5>
                <p>Dr. Jeff Odesa</p>
                <p>Mr. Ikechukwu Osegbu</p>
                <p>Mr. Odunayo Adebayo</p>
                <p>Mrs. Remilekun Ogunleye</p>
            </div>
        </div>
    </div>
  )
}

export default EditorialContent