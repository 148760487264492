import React from 'react'
import SideMenu from './SideMenu'

const AimsAndScopes = () => {
  return (
    <div className="aims-scopes__wrapper">
        <h1>Aims and Scope</h1>
        <div className="aims-scopes__container">
            <p>
                The International Journal of Accounting Issues (IJCAI) is established for the purpose of publishing and maintaining high-quality research in accountancy and finance. Nigerian-based and worldwide in scope, IJCAI seeks to provide a global forum to enhance communication among academics and professionals in emerging areas of accountancy and finance research and practice. This will be achieved through methodological strategies of understanding and solving critical developing problems and issues related to accountancy and finance.
                Conceptual and empirical submissions are encouraged to all areas of accountancy and finance and other cognate disciplines such as:
            </p>
            <SideMenu />
        </div>
        <div className="aims-area__container">
            <div className="area-item">
                <h5>Established Areas</h5>
                <ul>
                    <li>Financial Accounting</li>
                    <li>Management Accounting &amp; Controls</li>
                    <li>Audit and Assurance</li>
                    <li>Public Sector Accounting &amp; Accountability</li>
                    <li>Ethical Issues in Accounting and Finance</li>
                    <li>Standard Setting Accounting Regulations</li>
                    <li>Development in Accounting Profession in Emerging Economies</li>
                    <li>Information Systems</li>
                    <li>Capital Markets</li>
                    <li>Corporate Finance</li>
                    <li>Financial Institutions</li>
                    <li>Financial Management</li>
                    <li>Behavioural Financial and Accounting Research</li>
                    <li>Commercial Law</li>
                    <li>Financial Planning and Advice</li>
                    <li>Business Administration</li>
                    <li>Economics</li>
                    <li>Operations Management</li>
                </ul>
            </div>
            <div className="area-item">
                <h5>Emergent Areas</h5>
                <ul>
                    <li>Forensic Accounting</li>
                    <li>Fraud - Prevention, Detection and   Response</li> 
                    <li>Financial Criminology</li>
                    <li>Accounting Theory &amp; History</li>
                    <li>Taxation Policy and Outcomes</li>
                    <li>Environmental Accounting</li>
                    <li>Corporate and Behavioural Governance</li>
                    <li>Human Resources Accounting</li>
                    <li>Internet Reporting</li>
                    <li>Alternative Reporting Formats</li>
                    <li>Integrated Reporting</li>
                    <li>Accounting and E-business</li>
                    <li>Non-financial Performance Measurement and Reporting</li>
                    <li>Entrepreneurship</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default AimsAndScopes