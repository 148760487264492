import React, { useEffect, useState } from 'react'
import SideMenu from './SideMenu'
import './styles.css'
import allArticles from '../utils/articles.json'
import ArticlesContainer from './ArticlesContainer'
import { useSearchParams } from 'react-router-dom'

const ArticlesDisplay = () => {
  const [searchParams] = useSearchParams();
  const [filteredArticles, setFilteredArticles] = useState([]);

  const scroll = (value) => {
    const section = document.querySelector( value );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  useEffect(() => {
    const newArray = [];
    if (searchParams.get('search') !== null) {
      allArticles.forEach((item) => {
        if (item.title.toLowerCase().includes(searchParams.get('search').toLowerCase())) {
          newArray.push(item);
        }
      })
      scroll('#articles');
    } else {
      newArray.push(...allArticles);
    }
    setFilteredArticles(newArray);
  }, [searchParams]);

  return (
    <div className="instructions__wrapper container">
        <div className="instruction-content__container articles">
            <SideMenu />
        </div>
        <ArticlesContainer articles={filteredArticles} />
    </div>
  )
}

export default ArticlesDisplay