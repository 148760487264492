import React, { useEffect } from 'react'
import JournalOverview from '../components/JournalOverview'
import JournalVolume from '../components/JournalVolume'
import Menu from '../components/Menu'

const JournalPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <div>
      <div className="home-section-one">
        <JournalVolume  />
      </div>
      <div className="home-section-two">
        <Menu />
      </div>
      <div className="home-section-three">
        <JournalOverview />
      </div>
    </div>
  )
}

export default JournalPage