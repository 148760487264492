import React, { useState } from 'react'

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
    };

    const scrollToPageTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
    };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div className="scroll-top__wrapper">
        <button onClick={scrollToPageTop} style={{visibility: visible ? 'visible' : 'hidden'}}>
            <i className='fa fa-arrow-up' />
        </button>
    </div>
  )
}

export default ScrollToTop