import React from 'react'
import JournalName from './JournalName'
import ArticleImage from '../assets/images/article.jpg'
import './styles.css'
import { Link } from 'react-router-dom'

const ArticleVolume = () => {
  return (
    <div className='journal-volume__wrapper'>
        <div className="journal-volume__container container">
            <div className="volume-section__one">
                <JournalName name="International Journal of Contemporary Accounting Issues (IJCAI)" />
                <p>The International Journal of Contemporary Accounting Issues (IJCAI) welcomes and publishes high-quality accounting and finance contributions covering international contemporary topics across multiple research methodologies encompassing quantitative and qualitative methods. Authors seeking to contribute to contemporary debate in financial accounting, management accounting, public sector accounting, auditing, social and environmental accounting, accounting education and history are welcome to submit their manuscript to the journal for consideration.</p>
            </div>
            <div className="volume-section__two">
                <div className="volume-card__section">
                    <div className="volume-card">
                        {/* <div className="card-top">
                        <h5>International Journal of Contemporary Accounting Issues (IJCAI)</h5>
                        <p>Editor-in-chief: Prof. Abhulimen R.</p>
                        <p>Anao Benson ldahosa University,</p>
                        <p>Benin City</p>
                        </div>
                        <div className="card-bottom">
                            <h5>PDF</h5>
                        </div> */}
                        <img src={ArticleImage} alt="" />
                    </div>
                    <h2><Link to="/files/VOL_12_ISSUE_1.pdf" target="_blank" download>Volume 12, Issue 1</Link></h2>
                    <h2><Link to="/files/IJCAI-VOL-11-NO-2.pdf" target="_blank" download>Volume 11, Issue 2 September 2022</Link></h2>
                    <h2><Link to="/files/IJCAI-2022-VOL-11-NO-1.pdf" target="_blank" download>Volume 11, Issue 1 March 2022</Link></h2>
                    <h2><Link to="/files/VOLUME-10-EDITION-2.pdf" target="_blank" download>Volume 10, Issue 2 June 2021</Link></h2>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ArticleVolume